@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  /* @font-face {
    font-family: 'CatchyMager';
    src: local('CatchyMager'), url('./font/Catchy Mager Regular.ttf') format('woff');
}

  @font-face {
    font-family: 'Raleway';
    src: local('Raleway'), url('./font/Raleway-Italic-VariableFont_wght.ttf') format('woff');
  } */

  body {
    /* @apply text-primary font-secondary bg-white; */
    background-color: rgb(176, 55, 55);
  }

}
